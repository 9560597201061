.App {
  position: relative;
  width: 868px;
  text-align: center;
  margin: 0 auto;
}

/**************     top_layer     **************/
#top_layer {
  height: 80px;
  margin: 0 9px 10px 9px;
}

#top_layer #logo {
  position: absolute;
  left: 0px;
  top: 10px;
}

#top_layer #toptitle {
  position: absolute;
  left: 250px;
  top: 31px;
  font-family: times;
  font-size: 26px;
  font-weight: bold;
  color: #ffff99;
}

#top_layer #lang {
  position: absolute;
  left: 730px;
  top: 31px;
}

#top_layer #lang select {
  width: 100px;
}

/**************     body_layer      **************/
#body_bg {
  background: url('../public/images/body_bg.gif') repeat-y transparent;
  margin: 0 2px;
}

#body {
  background: url('../public/images/body-top.gif') repeat-x top #FFF;
  margin: 0 7px;
  padding: 30px 20px 20px 20px;
}

#body_bottom {
  width: 868px;
  height: 12px;
  background: url('../public/images/body_bottom.gif') no-repeat top;
}


/**************     left_column      **************/
#left {
  width: 136px;
  float: left;
}

#left_menu {
  margin-bottom: 20px;
}

#left_menu li {
  cursor: pointer;
  background: #8080ff;
  border-bottom: 1px solid #a0a0ff;
  padding-left: 0px;
}

#left_menu .last {
  border-bottom: 0
}

#left_menu a {
  display: block;
  width: 129px;
  padding-left: 7px;
}

#left_menu li,
#left_menu a {
  font-family: Garamond, 'MS Serif';
  font-size: 16px;
  font-weight: bold;
  color: #000077;
}

#left_menu li a:hover {
  background: #803aff;
}

/** submenu **/
#left_menu ul {
  /*display: none;*/
}

#left_menu ul li {
  background: #a0a0ff;
}

#left_menu ul li a:hover {
  background: #a05aff;
}

.small {
  font-family: times;
  font-size: 11px;
  color: #5555f5;
}

/**************     right_column      **************/
.main {
  text-align: left;
  min-height: 650px;
  margin-left: 20px;
  margin-top: 20px;
  width: 654px;
  float:left;
}

.main #header {
  width: 100%;
  padding: 20px 0px;
  font: bold 28px times;
  color: #000077;
  text-align: center;
}

/**************     bottom_layer     **************/
#bottom_layer {
  width: 100%;
}

/**************     shay      **************/
#shay h2{font-family:arial; font-size:22px; font-weight:bold;margin: 18px 0 15px 24px;}
#shay ul{margin: 1em 0;padding-left: 40px;}
#shay li{list-style-type: disc;}
#shay p{margin: 1em 0;}
#shay p{font-family:verdana,arial; font-size:15px;}
#shay a{text-decoration:none; font-family:times; font-size:14px; font-weight:bold; color:#0000ff;}
#shay .myname{font-family:times; font-size:140%; font-weight:bold;}

#shay table{width: 100%;background: url("../public/images/bg07.jpg") repeat;}
#shay table td{border: 1px solid black;}
/**************     munk      **************/
#munk table#t1 td{padding: 40px;}
#munk table#t2 td{padding: 30px;}

/**************     album      **************/
#main_album  #title{font: bold 28px Garamond, 'MS Serif';text-align: center;line-height: 50px;}
